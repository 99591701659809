import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
export const buttonTheme = defineStyleConfig({
    defaultProps: {
        variant: 'solid',
        size: 'md',
        colorScheme: 'gray',
    },
    variants: {
        pill: defineStyle(({ colorScheme }) => {
            return {
                backgroundColor: 'transparent',
                color: `${colorScheme}.700`,
                _hover: {
                    backgroundColor: `${colorScheme}.500`,
                    _disabled: { bg: 'transparent' },
                    color: 'white',
                    '& svg': {
                        color: 'white',
                        borderColor: 'white',
                    },
                },
                _active: {
                    backgroundColor: `${colorScheme}.700`,
                    color: 'white',
                    '& svg': {
                        color: 'white',
                        borderColor: 'white',
                    },
                },
            };
        }),
        active: defineStyle(({ colorScheme }) => {
            const bgStart = `${colorScheme}.100`;
            const bgHover = `${colorScheme}.200`;
            const bgActive = `${colorScheme}.300`;
            const textColor = `${colorScheme}.600`;
            return {
                bg: bgStart,
                color: textColor,
                _hover: {
                    bg: bgHover,
                    color: textColor,
                },
                _active: {
                    bg: bgActive,
                    color: textColor,
                },
            };
        }),
        ghost: defineStyle(({ colorScheme }) => {
            const bgStart = 'transparent';
            let bgHover = `${colorScheme}.400`;
            let bgActive = `${colorScheme}.50`;
            let textColor = `${colorScheme}.700`;
            if (colorScheme === 'dark') {
                bgHover = 'gray.700';
                bgActive = 'gray.600';
                textColor = 'white';
            }
            if (colorScheme === 'light') {
                bgHover = 'gray.100';
                bgActive = 'gray.200';
                textColor = 'gray.700';
            }
            return {
                bg: bgStart,
                color: textColor,
                _hover: {
                    bg: bgHover,
                    _disabled: { bg: bgStart },
                    color: `${colorScheme}.900`,
                },
                _active: { bg: bgActive, color: `${colorScheme}.900` },
            };
        }),
        outline: defineStyle(({ colorScheme }) => {
            const isWhiteAlpha = colorScheme === 'whiteAlpha';
            const bgStart = 'transparent';
            let bgHover = `${colorScheme}.50`;
            let bgActive = `${colorScheme}.100`;
            let textColor = isWhiteAlpha ? `${colorScheme}.900` : `${colorScheme}.800`;
            let borderColor = isWhiteAlpha ? `${colorScheme}.900` : `${colorScheme}.800`;
            if (colorScheme === 'gray') {
                bgHover = 'gray.100';
                bgActive = 'gray.200';
                textColor = 'gray.700';
                borderColor = 'gray.200';
            }
            return {
                bg: bgStart,
                color: textColor,
                border: '1px solid',
                borderColor: borderColor,
                _hover: {
                    bg: bgHover,
                    borderColor: borderColor,
                    _disabled: { bg: bgStart },
                },
                _active: { bg: bgActive },
            };
        }),
        secondary: defineStyle(({ colorScheme }) => {
            let textColor;
            let bgStart;
            let bgActive;
            let bgHover;
            if (colorScheme === 'gray') {
                textColor = 'gray.700';
                bgStart = 'gray.50';
                bgHover = 'gray.100';
                bgActive = 'gray.200';
            }
            if (colorScheme === 'darkGray') {
                textColor = 'white';
                bgStart = 'gray.700';
                bgHover = 'gray.600';
                bgActive = 'gray.500';
            }
            if (colorScheme === 'blue') {
                textColor = 'blue.800';
                bgStart = 'blue.100';
                bgHover = 'blue.200';
                bgActive = 'blue.300';
            }
            if (colorScheme === 'yellow') {
                textColor = 'yellow.900';
                bgStart = 'yellow.100';
                bgHover = 'yellow.200';
                bgActive = 'yellow.300';
            }
            if (colorScheme === 'purple') {
                textColor = 'purple.700';
                bgStart = 'purple.50';
                bgHover = 'purple.100';
                bgActive = 'purple.200';
            }
            return {
                bg: bgStart,
                color: textColor,
                _hover: {
                    bg: bgHover,
                    _disabled: { bg: bgStart },
                },
                _active: { bg: bgActive },
            };
        }),
        solid: defineStyle(({ colorScheme }) => {
            const isWhite = colorScheme === 'white';
            const isSubtle = colorScheme === 'gray';
            const isContrast = colorScheme === 'whiteAlpha' || isWhite || colorScheme === 'green';
            let bgStart = isSubtle ? `${colorScheme}.100` : isWhite ? 'white' : `${colorScheme}.500`;
            let bgHover = isSubtle ? `${colorScheme}.200` : isWhite ? 'gray.50' : `${colorScheme}.600`;
            let bgActive = isSubtle ? `${colorScheme}.300` : isWhite ? 'gray.100' : `${colorScheme}.700`;
            let textColor = isSubtle ? `${colorScheme}.800` : isContrast ? 'black' : 'white';
            if (colorScheme === 'dark') {
                bgStart = 'gray.700';
                bgHover = 'gray.600';
                bgActive = 'gray.500';
                textColor = 'white';
            }
            else if (colorScheme === 'yellow') {
                bgStart = 'yellow.500';
                bgHover = 'yellow.600';
                bgActive = 'yellow.700';
                textColor = 'gray.700';
            }
            return {
                bg: bgStart,
                color: textColor,
                _hover: {
                    bg: bgHover,
                    color: textColor,
                    _disabled: { bg: bgStart },
                },
                _active: { bg: bgActive },
                _focus: { color: textColor },
            };
        }),
        unstyled: defineStyle({
            bg: 'none',
            color: 'inherit',
            display: 'inline',
            lineHeight: 'inherit',
            fontWeight: 'normal',
            fontSize: 'inherit',
            textAlign: 'left',
            m: 0,
            p: 0,
        }),
        selectBox: defineStyle({
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            textAlign: 'left',
            bgColor: 'gray.50',
            borderRadius: 'md',
            height: '28px',
            minHeight: '28px',
            border: '2px solid',
            borderColor: 'gray.50',
            fontSize: 'sm',
            fontWeight: 'regular',
            pl: '2px',
            pr: 0,
            outline: 'none',
            _focus: {
                outline: 'none',
                borderColor: 'blue.500',
                bgColor: 'white',
            },
            _hover: {
                borderColor: 'gray.100',
            },
            _active: {
                borderColor: 'blue.500',
                bgColor: 'white',
            },
        }),
    },
    sizes: {
        lg: () => {
            return {
                h: 12,
                minW: 12,
                fontSize: 'lg',
                px: 6,
                svg: { w: 7, h: 7 },
            };
        },
        md: () => {
            return {
                h: 10,
                minW: 10,
                fontSize: 'md',
                px: 4,
                svg: { w: 6, h: 6 },
            };
        },
        sm: () => {
            return {
                h: 8,
                minW: 8,
                px: 3,
                fontSize: 'sm',
                svg: { w: 5, h: 5 },
            };
        },
        xs: () => {
            return {
                h: 6,
                minW: 6,
                px: 2,
                fontSize: 'xs',
                svg: { w: 4, h: 4 },
                borderRadius: 'sm',
            };
        },
    },
    baseStyle: {
        lineHeight: '1.2',
        borderRadius: 'md',
        fontWeight: 'medium',
        transitionProperty: 'common',
        transitionDuration: 'normal',
        textDecoration: 'none',
        _focus: { boxShadow: 'none' },
        _focusVisible: {
            outline: '0.125rem solid',
            outlineColor: `blue.500`,
            outlineOffset: '0.125rem',
        },
        _disabled: { opacity: 0.4, cursor: 'not-allowed', boxShadow: 'none' },
        _hover: { textDecoration: 'none', _disabled: { bg: 'initial' } },
    },
});
